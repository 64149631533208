import React from 'react';
import ReactDOM from 'react-dom';
import { SnackbarProvider } from "notistack";
import axios from 'axios';

import './index.css';
import App from './App';
// Facebook
import facebookInit from './utils/facebook/facebook-init'

// Create default axios url
axios.defaults.baseURL = `${process.env.REACT_APP_BACKEND_API}`;

// Init facebook sdk after the entire app load
facebookInit({ appID: process.env.REACT_APP_APP_ID }).then(res => {
  startApp({ loginStatus: res });
});

const startApp = ({loginStatus}) => {
  return ReactDOM.render(
    <React.StrictMode>
      <SnackbarProvider maxSnack={4}>
        <App loginStatus={loginStatus} />
      </SnackbarProvider>
    </React.StrictMode>,
    document.getElementById('root')
  );
}

