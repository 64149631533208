import React from "react";
import { CircularProgress, Container } from "@material-ui/core/";

const Spinner = () => {
  return (
    <Container>
      <div
        style={{
          position: "fixed",
          width: "100vw",
          height: "100vh",
          top: "0",
          left: "0",
          zIndex: "1111",
          backgroundColor: "rgba(255, 255, 255, 0.9)",
        }}
      ></div>
      <div
        style={{
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%,-50%)",
          zIndex: "1112",
        }}
      >
        <CircularProgress size={105} disableShrink />
      </div>
    </Container>
  );
};

export default Spinner;
