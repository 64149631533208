import getLoginStatus from "../getLoginStatus";

const facebookInit = ({ appID }) => {
  return new Promise(resolve => {
    window.fbAsyncInit = function() {
      window.FB.init({
        appId: appID,
        status:true,
        xfbml: true,
        version:'v11.0',
      });
      getLoginStatus().then(res => {
        // Call resolve here ! otherwise, FB will not attached to window obj yet ! 
        // Get current login status for routing
        resolve(res)
      })
    };
    // Load the SDK asynchronously
    (function(d, s, id) {
      var js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      js = d.createElement(s); js.id = id;
      js.src = "//connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'facebook-jssdk'));
  })
}

export default facebookInit