import React from 'react';
import { Redirect, Route } from 'react-router-dom';

const RouteX = ({ component: Component, redirect,loginStatus, ...rest }) => {
  
  return (
    <Route
      {...rest}
      render={( props ) => (
        loginStatus.status === "connected"
          ? <Redirect to={redirect} />
          : <Component {...props} />
      )}
    />
  )
}
;

export default RouteX;
