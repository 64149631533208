import {
  lazy,
  Suspense,
} from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";

import Spinner from './components/feedback-user/spinner'
import RouteX from './components/routes/routeX';
import PrivateRoute from './components/routes/private-route'
// Context
import { PageInfoContextProvider } from './context/page-info-context'

// Lazy to Load Pages
const LoginPage = lazy(() => import('./pages/login'));
const ProfilePage = lazy(() => import('./pages/profile'));
const HomePage = lazy(() => import('./pages/home'));
const VideosPage = lazy(()=>import('./pages/videos'))
const CommentsPage = lazy(()=>import('./pages/comments'))

const App = ({loginStatus}) => {

  return (
    <Router>
      <PageInfoContextProvider>
        <Suspense fallback={<Spinner />}>
          <Switch>
            <RouteX path="/login" component={LoginPage} redirect="/" loginStatus={loginStatus} />
            <PrivateRoute path="/profile" loginStatus={loginStatus} >
              <ProfilePage/>
            </PrivateRoute>
            <PrivateRoute path="/:pageID/videos" loginStatus={loginStatus} >
              <VideosPage/>
            </PrivateRoute>
            <PrivateRoute path="/:pageID/:liveID/comments" loginStatus={loginStatus}>
              <CommentsPage/>
            </PrivateRoute>
            <PrivateRoute path="/" loginStatus={loginStatus}>
              <HomePage/>
            </PrivateRoute>
            <Route path="/*">
              <Redirect to="/login" loginStatus={loginStatus}/>
            </Route>
          </Switch>
        </Suspense>
      </PageInfoContextProvider>
    </Router>
  )
}

export default App;