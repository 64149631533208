import React from 'react';
import { Redirect, Route } from 'react-router-dom';

const PrivateRoute = ({ component: Component, children,loginStatus, ...rest }) => {
  
  return (
    <Route
      {...rest}
    >
      {
        loginStatus.status === "connected"
        ? children
        : <Redirect to="/login" />
      }
    </Route>
  )
};

export default PrivateRoute;
