const getPages = async () => {
  return new Promise(resolve => {
    // Get pages the current user manages
    window.FB.api(
      `/me/accounts`,
      'GET',
      { "fields": "name,picture,access_token" },
      function(response) {
        // If there's any error
        if (response.error) {
          return resolve({ getPagesFailed: true });
        }
        // Otherwise return data
        resolve({ pages: response });
      }
    );
  })
}

export default getPages;