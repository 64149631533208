import axios from "axios";
import { createContext, useState, useEffect } from "react";

// Facebook
import getPages from "../../utils/facebook/get-pages";

const PageInfoContext = createContext({
  pageInfo: [],
  setPageInfo: () => {},
  dashboardURL: "",
});

const PageInfoContextProvider = ({ children }) => {
  // State handle page info
  const [pageInfo, setPageInfo] = useState([]);
  const [dashboardURL, setDashboardURL] = useState("");

  useEffect(() => {
    (async function () {
      try {
        // Use it to fetch pages and its accessTokens
        const { pages = { data: [] }, getPagesFailed } = await getPages();
        // get dashboard url
        const { data } = await axios.get("/api/v1/setting/get-dashboard-url");

        // setState dashboard url
        setDashboardURL(data.dashboardURL)
        // SetState if get page successfully
        if (!getPagesFailed) {
          setPageInfo(pages.data);
        }
      } catch (err) {
        console.log(err);
      }
    })();
  }, []);

  return (
    <PageInfoContext.Provider value={{ pageInfo, setPageInfo, dashboardURL }}>
      {children}
    </PageInfoContext.Provider>
  );
};

export { PageInfoContext, PageInfoContextProvider };
